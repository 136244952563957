import {
  type Social,
  SOCIAL_HOSTNAME_PHRASE,
  windowService,
} from '~/domains/core';
import SocialsJSON from '~/domains/core/infrastructure/data/social/socials.json';
import has from 'lodash-es/has';

export default (): Social[] => {
  const hostname = windowService.getLocation().hostname;

  if (
    hostname.includes(SOCIAL_HOSTNAME_PHRASE.ASBK) &&
    has(SocialsJSON, hostname)
  ) {
    return SocialsJSON[hostname] as Social[];
  }

  return SocialsJSON.default;
};
