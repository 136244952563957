import {
  asbisDark,
  asbisLight,
  asbkDark,
  asbkLight,
  breezyDark,
  breezyLight,
} from './images';
import { type Logos } from '~/domains/core';

export const logoConfig: Logos = {
  asbis: {
    dark: asbisDark,
    light: asbisLight,
  },
  asbc: {
    dark: asbkDark,
    light: asbkLight,
  },
  breezy: {
    dark: breezyDark,
    light: breezyLight,
  },
};
