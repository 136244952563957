import {
  type Logo,
  SOCIAL_HOSTNAME_PHRASE,
  windowService,
} from '~/domains/core';
import { logoConfig } from './logoConfig.ts';

export default (): Logo => {
  const hostname = windowService.getLocation().hostname;

  if (hostname.includes(SOCIAL_HOSTNAME_PHRASE.ASBK)) {
    return logoConfig.asbc;
  }

  if (hostname.includes(SOCIAL_HOSTNAME_PHRASE.BREEZY)) {
    return logoConfig.breezy;
  }

  return logoConfig.asbis;
};
