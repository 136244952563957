import { type RouteLocationNormalized } from 'vue-router';
import { useAuthStore, authService, PATH_DICTIONARY } from '~/domains/auth';
import { getUserInfoViewModel, useUserStore } from '~/domains/user';
import { useUserPreferences } from '~/domains/user/composables/useUserPreferences.ts';
import { usePermissions } from '~/infrastructure';

const authGuard = async (
  to: RouteLocationNormalized,
): Promise<string | boolean> => {
  const authToken = authService.getAuthToken();

  const authStore = useAuthStore();
  const { getUserPreferences } = useUserPreferences();
  const { fetchUserInfo } = getUserInfoViewModel();
  const { checkAccess } = usePermissions();

  if (authStore.isAuth) {
    const isNeedToChangePassword =
      localStorage.getItem('isNeedToChangePassword') === 'true';

    if (isNeedToChangePassword && to.path !== PATH_DICTIONARY.changePassword) {
      return PATH_DICTIONARY.changePassword;
    }

    if (isNeedToChangePassword && to.path === PATH_DICTIONARY.changePassword) {
      return true;
    }

    if (to.meta.layout === 'auth') return PATH_DICTIONARY.home;

    return true;
  }

  if (typeof authToken === 'string' && authToken !== '') {
    const userStore = useUserStore();
    try {
      const getUserResponse = await fetchUserInfo();

      if (getUserResponse == null) {
        throw new Error('No user response');
      } else if (localStorage.getItem('isNeedToChangePassword') !== 'true') {
        userStore.setUser(getUserResponse);
        getUserPreferences();

        if (to.meta.layout === 'auth') {
          return PATH_DICTIONARY.home;
        }

        if (!checkAccess(to)) {
          return PATH_DICTIONARY.home;
        }

        return true;
      }
    } catch (e) {
      authService.removeAuthToken();
    }
  }

  if (to.path === PATH_DICTIONARY.changePassword) {
    return PATH_DICTIONARY.login;
  }

  if (to.meta.layout === 'auth') return true;

  authStore.setRedirectAfterLogin(to.fullPath);

  return PATH_DICTIONARY.login;
};

export default authGuard;
