import type { WarehouseTypeRequest } from './entitites';

export const WAREHOUSE_TYPE: Record<string, WarehouseTypeRequest> = {
  system: 'SYSTEM',
  dropship: 'DROPSHIP',
};

export const SOCIAL_HOSTNAME_PHRASE = {
  ASBK: '.asbc',
  ASBIS: '.asbis',
  BREEZY: '.breezy',
};
